/** @format */
@import '@styles/variables';
@import '@styles/mixin';
@import '@styles/banner_global';
.banner {
	.bannerSlider {
		img {
			display: block;
			width: 100%;
		}
		.linkType {
			width: 100%;
		}
	}
	img {
		cursor: pointer;
	}
	.bannerImage {
		width: 100%;
		span {
			position: unset !important;
		}
		.image {
			width: 100% !important;
			position: $relative !important;
			height: unset !important;
		}
		.linkType {
			width: 100%;
		}
	}
	.swiper-slide {
		background-size: cover;
	}
}
.linkType {
	width: 100%;
}
.imgHome {
	text-align: center;
	img {
		width: 100%;
	}
}
