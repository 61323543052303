@import '@styles/variables';
@import '@styles/mixin';

.swiper {
	width: 100%;
	height: 100%;
	padding-bottom: 22px !important;
}
.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;

	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	cursor: pointer;
}

.swiperPagination {
	position: absolute;
	text-align: center;
	transition: 300ms opacity;
	transform: translate3d(0, 0, 0);
	z-index: 10;
	top: 0 !important;
}
.swiper-pagination-bullet {
	background: #242121 !important;
	width: 10px !important;
	height: 10px !important;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
	bottom: -6px !important;
}
.swiper-pagination-bullet-active {
	opacity: 1;
	background: #869791 !important;
	margin-top: 50px;
}

/*move swiper pagination on top to image */
